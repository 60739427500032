import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Spinner, Card } from "react-bootstrap";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { FaUser } from "react-icons/fa";
import { renderToStaticMarkup } from "react-dom/server";
import "./BikersPage.css";
import { useLocation } from "react-router-dom";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const apiUrl = "https://api.grupocarosa.com/ApiDatos";

const centralPoint = {
  lat: 13.691853,
  lng: -89.219394,
};

const getStatusColor = (status) => {
  switch (status) {
    case "Disponible":
      return "green";
    case "En viaje":
      return "blue";
    case "Receso":
      return "#FFA500";
    case "En recepción":
      return "black";
    case "No disponible":
      return "gray";
    default:
      return "white";
  }
};

const createIconURL = () => {
  const icon = <FaUser size={18} color="blue" />;
  const svgString = renderToStaticMarkup(icon);
  const encoded = encodeURIComponent(svgString);
  return `data:image/svg+xml;charset=UTF-8,${encoded}`;
};

const MapComponent = ({ motociclistas, centerMapCoords }) => {
  const mapRef = useRef(null);
  const [selectedMoto, setSelectedMoto] = useState(null);
  const iconUrl = createIconURL();
  const markerCluster = useRef(null);

  useEffect(() => {
    if (centerMapCoords) {
      centerMap(centerMapCoords.lat, centerMapCoords.lng);
    }
  }, [centerMapCoords]);

  useEffect(() => {
    if (mapRef.current && motociclistas.length > 0) {
      const markers = motociclistas.map((moto) => {
        const marker = new window.google.maps.Marker({
          position: {
            lat: parseFloat(moto.LATITUD),
            lng: parseFloat(moto.LONGITUD),
          },
          icon: {
            url: iconUrl,
            scaledSize: new window.google.maps.Size(28, 28),
            anchor: new window.google.maps.Point(19, 19),
          },
          title: `${moto.COBRADOR} - ${moto.NOMBRE}`,
        });

        marker.addListener("click", () => {
          setSelectedMoto(moto);
        });

        return marker;
      });

      if (markerCluster.current) {
        markerCluster.current.clearMarkers();
      }

      markerCluster.current = new MarkerClusterer({ map: mapRef.current, markers });
    }
  }, [motociclistas, iconUrl]);

  const centerMap = (lat, lng) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(18);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{ height: "85vh", width: "100%" }}
      center={centralPoint}
      zoom={13}
      onLoad={(map) => (mapRef.current = map)}
    >
      {selectedMoto && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedMoto.LATITUD),
            lng: parseFloat(selectedMoto.LONGITUD),
          }}
          onCloseClick={() => setSelectedMoto(null)}
        >
          <div className="Card-map">
            <p>{selectedMoto.COBRADOR} {selectedMoto.NOMBRE}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

const fetchTGetMotociclistas = async () => {
  const response = await fetch(`${apiUrl}/vendedores`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const responseData = await response.json();
  return responseData;
};

const BikersPage = () => {
  const [motociclistas, setMotociclistas] = useState([]);
  const [centerMapCoords, setCenterMapCoords] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDWaMvjQhth5It2thtLVHMZ8XpoOAdBKSk",
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedMotociclistas = [...motociclistas].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const location = useLocation();

  const formatDate = (dateString) => {
    const timestamp = parseInt(
      dateString.replace("/Date(", "").replace(")/", "")
    );

    const date = new Date(timestamp);

    return date.toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const fetchMoto = async () => {
    try {
      const data = await fetchTGetMotociclistas();
      console.log("Respuesta de la API:", data);

      if (Array.isArray(data)) {
        const Motociclistas = data.filter(
          (moto) => moto.LATITUD !== null && moto.LONGITUD !== null
        );
        setMotociclistas(Motociclistas);
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error("Error obtaining bikers:", error);
    }
  };

  useEffect(() => {
    fetchMoto();

    if (location.state && location.state.centerMapCoords) {
      setCenterMapCoords(location.state.centerMapCoords);
    }

    const intervalId = setInterval(fetchMoto, 60000);

    return () => clearInterval(intervalId);
  }, [location.state]);

  const handleRowClick = (lat, lng) => {
    setCenterMapCoords({ lat, lng });
  };

  return (
    <Container fluid>
      <Row>
        <Col
          xs={8}
          className="position-relative"
          style={{ height: "85vh !important" }}
        >
          {isLoaded ? (
            <div style={{ height: "100%", width: "100%" }}>
              <MapComponent
                motociclistas={motociclistas}
                centerMapCoords={centerMapCoords}
              />
            </div>
          ) : (
            <Spinner animation="border" />
          )}
        </Col>
        <Col xs={4} style={{ height: "85vh" }}>
          <Card className="mt-0 card-body">
            <Card.Body>
              <div className="table-container">
                <Row>
                  <Col
                    xs={3}
                    className="padding-right"
                    onClick={() => handleSort("COBRADOR")}
                  >
                    <strong>
                      Código{" "}
                      {sortConfig.key === "COBRADOR"
                        ? sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </strong>
                  </Col>
                  <Col
                    xs={3}
                    className="no-padding"
                    onClick={() => handleSort("NOMBRE")}
                  >
                    <strong>
                      Nombre{" "}
                      {sortConfig.key === "NOMBRE"
                        ? sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </strong>
                  </Col>
                  <Col onClick={() => handleSort("ULTIMA_ACT")}>
                    <strong>
                      Última actualización{" "}
                      {sortConfig.key === "ULTIMA_ACT"
                        ? sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </strong>
                  </Col>
                </Row>
                <div className="scrollable-content">
                  {sortedMotociclistas.map((moto) => (
                    <Row
                      key={moto.COBRADOR}
                      className="align-items-center mt-2 table-row"
                      onClick={() =>
                        handleRowClick(
                          parseFloat(moto.LATITUD),
                          parseFloat(moto.LONGITUD)
                        )
                      }
                    >
                      <Col xs={1} className="no-padding">
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              moto.ESTADO_NOMBRE ?? "No disponible"
                            ),
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </Col>
                      <Col
                        xs={1}
                        className="text-wrap custom-font-size no-padding-h"
                      >
                        {moto.COBRADOR}
                      </Col>
                      <Col xs={5} className="text-wrap custom-font-size">
                        {moto.NOMBRE}
                      </Col>
                      <Col xs={5} className="text-wrap custom-font-size">
                        {formatDate(moto.ULTIMA_ACT)}
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BikersPage;
